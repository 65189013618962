import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useNavigate } from "react-router-dom";
import { useRef, useState, useEffect, useContext } from "react";
import AuthContext from "../login/context/AuthProvider";
//import { AuthProvider } from "../login/context/AuthProvider"

import Errors from "../Screens/error";
import Opbouw from "../Opbouw/opbouw";
import Settings from "../Screens/settings";
import Options from "../Screens/options";
import Projects from "../Screens/projects";
import SaveAs from "../Screens/saveas";

import Infobar from "./infobar";
import "./navbar.css";
//import AuthContext from "../login/context/AuthProvider"
import { Token } from "@mui/icons-material";
import { Navigate, Outlet } from "react-router-dom";
//import { withRouter } from 'react-router-dom';
import { useAuth } from "../login/context/AuthProvider";

import {
  SaveProject,
  GetProjects,
  OpenProject,
  projectFromDatabase,
  UpdateProject,
  UpdateProject2,
  save2,
} from "../database/projects";

import { getCustomerById } from "../database/userInfo";

//var constants = require('../database/projects')

import testWoning from "./../data/testWoning.json";
import { Icon } from "@mui/material";
import { WoningIcon } from "../images/icons/svgIcons";
import { GenOff2, GenOverview, GenOverviewById } from "../database/generate";
import { mods1 } from "../Opbouw/Overzicht/sharedInterfaces";

interface MyProps {
  //ongetToken: () => any;
  onLoadedWoningChange: (woningArray: any) => any;
  //loadedWoning: any
  //history:any;
  //calculating: boolean;
  //startCalc: () => any;
  //stopCalc: () => any;
}
type MyState = {
  usertoken: string;
  settingsVisible: boolean;
  visibleModal: string;
  loadedWoning: {};
  uitvoering: string;
  klant: string;
  full: boolean;
  indienst: boolean;
  paketten: boolean;
  rel04_08: boolean;
  errorsVisible: boolean;
  errorMessage: string;

  //projects: projectFromDatabase;
};

class NavBar extends Component<MyProps, MyState> {
  navBarData = [
    {
      label: "Bestand",
      submenu: [
        {
          label: "Nieuw",
          eventKey: "new",
        },
        {
          label: "Openen",
          eventKey: "open",
        },
        {
          label: "Bewaren",
          eventKey: "save",
        },
        {
          label: "Maak offerte",
          eventKey: "generate",
        },
        {
          label: "Maak overzicht",
          eventKey: "generateOverview",
        },
      ],
    },
    {
      label: "Afwerking",
      submenu: [
        {
          label: "Niko",
          submenu: [
            {
              label: "Cream 100 RGB",
              eventKey: "100",
            },
            {
              label: "White 101 RGB",
              eventKey: "101",
            },
            {
              label: "Anthracite 122 RGB",
              eventKey: "122",
            },
            {
              label: "Black Coated 161 RGB",
              eventKey: "161",
            },
            {
              label: "No Buttons NNB RGB",
              eventKey: "NNB",
            },
          ],
        },
        {
          label: "Bticino",
          submenu: [
            {
              label: "N-Light White RGB",
              eventKey: "N",
            },
            {
              label: "L-Light Black RGB",
              eventKey: "L",
            },
            {
              label: "L-Light-Metal RGB",
              eventKey: "NT",
            },
          ],
        },
        {
          label: "Tastu",
          submenu: [
            {
              label: "GW Wit RGB",
              eventKey: "GW",
            },
            {
              label: "GB Zwart RGB",
              eventKey: "GB",
            },
          ],
        },
      ],
    },
    ,
    /*
    {
      label: "Opties",
      url: "",
      eventKey: "options",
    },
    ,
    {
      label: "Info",
      url: "",
    },
    */
    {
      label: "Instellingen",
      url: "",
      eventKey: "settings",
      location: "right",
    },
  ];

  constructor(props: any) {
    super(props);
    this.state = {
      usertoken: "",
      visibleModal: "",
      settingsVisible: false,
      loadedWoning: {},
      uitvoering: "",
      klant: "",
      full: true,
      paketten: false,
      rel04_08: false,
      indienst: false,
      errorsVisible: false,
      errorMessage: "",
    };
    this.handleWoningChanged = this.handleWoningChanged.bind(this);
    this.handleNewProject = this.handleNewProject.bind(this);
    this.handleInDienstChanged = this.handleInDienstChanged.bind(this);
  }

  reRenderInfo = false;

  modulesForOfferte: any = [];
  noSaModulesForOfferte: any = [];

  loadedWoning: any = [];

  woningToLoad: any = [];

  projects = {} as projectFromDatabase;
  project = {} as projectFromDatabase;

  visibleModalState = { visibleModal: "" };

  uitvoeringState = { uitvoering: "" };

  uitvoering = "";

  handleErrorScreen = (errorsVisisble: boolean) => {
    this.setState({ errorsVisible: errorsVisisble });
  };

  handleWoningChanged = (woningArray: any) => {
    this.project = woningArray;
  };

  changeModal = (selected: any) => {
    let modal = this.visibleModalState;
    modal.visibleModal = selected;
    return modal;
  };

  handleFullChange = (full: boolean) => {
    this.setState({ full: full });
  };

  handleInDienstChanged = (indienst: boolean) => {
    this.setState({ indienst: indienst });
  };

  handlePakketChanged = (paketten: boolean) => {
    this.setState({ paketten: paketten });
  };

  handleREL04_08Changed = (rel04_08: boolean) => {
    this.setState({ rel04_08: rel04_08 });
  };

  changeUitvoering = (uitvoering: string) => {
    let uitv = this.uitvoeringState;
    uitv.uitvoering = uitvoering;

    switch (uitvoering) {
      case "100":
        sessionStorage.setItem("uitvoeringText", "Niko - Cream 100 RGB");
        break;
      case "101":
        sessionStorage.setItem("uitvoeringText", "Niko - White 101 RGB");
        break;
      case "122":
        sessionStorage.setItem("uitvoeringText", "Niko - Anthracite 122 RGB");
        break;
      case "161":
        sessionStorage.setItem("uitvoeringText", "Niko - Black Coated 161 RGB");
        break;
      case "NNB":
        sessionStorage.setItem("uitvoeringText", "Niko - No Buttons NNB RGB");
        break;

      case "N":
        sessionStorage.setItem("uitvoeringText", "Bticino - N-Light White RGB");
        break;
      case "L":
        sessionStorage.setItem("uitvoeringText", "Bticino - L-Light Black RGB");
        break;
      case "NT":
        sessionStorage.setItem("uitvoeringText", "Bticino - L-Light-Metal RGB");
        break;

      case "GW":
        sessionStorage.setItem("uitvoeringText", "Tastu - GW Wit RGB");
        break;
      case "GB":
        sessionStorage.setItem("uitvoeringText", "Tastu - GB Zwart RGB");
        break;
    }

    sessionStorage.setItem("uitvoering", uitvoering);

    return uitv;
  };

  changeLoadedWoning = () => {
    let j = this.loadedWoning;
    return j;
  };

  async getProjects1() {
    GetProjects().then((data: any) => {
      this.projects = data;
      this.setState((this.visibleModalState = this.changeModal("open")));
    });
  }

  async saveProj(data: any, name: string, klant: number) {
    save2(data, name, klant).then((data: any) => {
      console.log("Project saved: ", data);
      //console.log(data.data.id);
      sessionStorage.setItem("projectId", JSON.stringify(data.data.id));
    });
    return data;
  }

  async updateProj(data: any, name: string, klant: number, id: number) {
    UpdateProject2(data, name, klant, id).then((data: any) => {
      //console.log("Project updated: ", data);
      //sessionStorage.setItem("projectId", JSON.stringify(data.data.id));
    });
    return data;
  }

  handleSaveProject = (filename: string) => {
    //console.log("proj: ", this.project);
    //const bestandsnaam = sessionStorage.getItem("bestandsnaam");
    const projId = sessionStorage.getItem("projectId");
    const klant = Number(sessionStorage.getItem("eindklantId"));
    //console.log(klant)
    if (projId) {
      // Todo Update by idµ
      console.log("UPDATE");
      this.updateProj(
        JSON.stringify(this.project),
        filename,
        klant,
        Number(projId)
      );
    } else {
      //Create new
      console.log("SAVE");
      this.saveProj(JSON.stringify(this.project), filename, klant);
    }
  };
  tempWO = "";

  async agetCustomerById(customerId: number) {
    getCustomerById(customerId).then((data: any) => {
      console.log("CUST");
      const userInfo = data.success[0];
      const naam = userInfo.naam;
      const voornaam = userInfo.voornaam;
      const eindklant = naam + " " + voornaam;

      sessionStorage.setItem("eindklant", eindklant);
      sessionStorage.setItem("eindklantId", userInfo.id);
    });
    await getCustomerById(customerId);
  }

  setCustomer = (eindklant: string, id: string) => {
    sessionStorage.setItem("eindklant", eindklant);
    sessionStorage.setItem("eindklantId", id);
    this.setState({ klant: eindklant });
  };

  handleOpenProject = (id: number) => {
    OpenProject(id).then((data: any) => {
      if (data.success) {
        const customerId = data.success[0].customerId;

        getCustomerById(customerId).then((data: any) => {
          //console.log("CUST")
          const userInfo = data.success[0];
          if (userInfo) {
            const naam = userInfo.naam;
            const voornaam = userInfo.voornaam;
            const eindklant = naam + " " + voornaam;

            //sessionStorage.setItem("eindklant", eindklant);
            //sessionStorage.setItem("eindklantId", userInfo.id);

            this.setCustomer(eindklant, userInfo.id);
          }
        });

        //this.agetCustomerById(customerId)

        //const proj = data.success[0].data;
        //console.log("openproj: ", proj);
        this.loadedWoning = data.success[0].data;

        //debugger
        this.setState((this.loadedWoning = this.changeLoadedWoning()));
        // this.setState("projectId", )
        sessionStorage.setItem("projectId", JSON.stringify(id));
        //sessionStorage.setItem('woningTree',  JSON.stringify(this.changeLoadedWoning()));

        const projName = data.success[0].name;

        let max = 0;
        this.loadedWoning.map((obj: any) => {
          let nid = parseInt(obj.nodeid);
          if (nid > max) {
            max = nid;
          }
        });

        let nextId = max + 1;
        sessionStorage.setItem("nodeId", nextId.toString());
      }
    });
  };

  handleGenerate = () => {
    //debugger
    // console.log(this.modulesForOfferte)

    const projId = sessionStorage.getItem("projectId");
    if (!projId) {
      this.setState({
        errorMessage:
          "Om een offerte te kunnen genereren moet uw project eerst bewaard worden.",
      });
      this.setState({ errorsVisible: true });
    } else {
      var filtered = this.modulesForOfferte.mods.filter(function (el: any) {
        return el.module != "INDIENST";
      });
      this.modulesForOfferte.mods = filtered;

      let nosamodl = Object.keys(this.noSaModulesForOfferte).length;

      if (nosamodl === 0) {
        if (this.state.indienst === true) {
        let indienstItem: any = {
          module: "INDIENST",
          aantal: 0,
          prijs: 88,
          vrij: 0,
          modType: "INDIENTS",
          busCons: 0,
          parentModule: 0,
          modId: 180,
          offCat: 3,
        };
        //const d:any = this.modulesForOfferte
        //d.push(indienstItem)
        this.modulesForOfferte.mods.push(indienstItem);
      }
      }
      
      
      GenOff2(this.modulesForOfferte, this.noSaModulesForOfferte).then((data: any) => {
        //console.log(data);
      });
    }
  };

  handleGenerateOverview = () => {
    //debugger
    //console.log(this.modulesForOfferte)
    /*
    this.setState({ errorMessage: "Deze module bestaat niet." });
          this.setState({ errorsVisible: true });
*/

    const projId = sessionStorage.getItem("projectId");
    // console.log("pid: ", projId);

    if (!projId) {
      this.setState({
        errorMessage:
          "Om een overzicht te kunnen genereren moet uw project eerst bewaard worden.",
      });
      this.setState({ errorsVisible: true });
    } else {
      GenOverviewById(projId).then((data: any) => {
        //console.log(data);
      });
    }

    /*
    GenOverview(this.project).then((data: any) => {
      console.log(data);


    });
    */
  };

  handlenew2 = () => {
    // const proj = data.success[0].data;
    //onsole.log("openproj: ", proj)
    this.loadedWoning = [];
    this.setState((this.loadedWoning = this.changeLoadedWoning()));
    // this.setState("projectId", )
    sessionStorage.setItem("projectId", "0");

    //sessionStorage.setItem("nodeId", "0");

    //this.openProject()
  };

  handleNewProject = () => {
    const l = this.loadedWoning;

    //console.log("LW: ", l);
    sessionStorage.removeItem("projectId");
    //sessionStorage.removeItem('nodeId');
    sessionStorage.removeItem("eindklantId");
    sessionStorage.removeItem("eindklant");
    sessionStorage.removeItem("bestandsnaam");
    sessionStorage.removeItem("woningTree");
    sessionStorage.setItem("nodeId", "5");
    sessionStorage.removeItem("loadedWoning");
    //sessionStorage.removeItem('woningTree');

    //this.project = {} as projectFromDatabase;

    const woningIcon: any = (
      <Icon key="woning">
        <WoningIcon />
      </Icon>
    );

    const rootTreeItem = {
      id: "1",
      name: "Woning",
      children: [],
      icon: woningIcon,
      expanded: true,
    };

    //this.project.success =

    this.loadedWoning = [rootTreeItem];
    // this.loadedWoning.children = []
    this.setState((this.loadedWoning = this.changeLoadedWoning()));
    sessionStorage.setItem("loadedWoning", JSON.stringify([]));
  };

  handleModules = (modules: any, noSaMods: any) => {
    //console.log("NAV: ", modules);
    this.modulesForOfferte = modules;
    this.noSaModulesForOfferte = noSaMods;
    //debugger
    //todo send to server
  };

  handleSelect = (eventKey: any) => {
    switch (eventKey) {
      case "open":
        this.getProjects1();
        break;
      case "save":
        this.setState((this.visibleModalState = this.changeModal("saveas")));
        break;
      case "new":
        //this.getProjects1();
        this.handleNewProject();
        break;
      case "generate":
        //console.log("generate");
        this.handleGenerate();
        break;
      case "generateOverview":
        //console.log("genergenerateOverviewte");
        this.handleGenerateOverview();
        break;

      case "100":
        this.setState((this.uitvoeringState = this.changeUitvoering("100")));
        break;
      case "101":
        this.setState((this.uitvoeringState = this.changeUitvoering("101")));
        break;
      case "161":
        this.setState((this.uitvoeringState = this.changeUitvoering("161")));
        break;
      case "122":
        this.setState((this.uitvoeringState = this.changeUitvoering("122")));
        break;
      case "NNB":
        this.setState((this.uitvoeringState = this.changeUitvoering("NNB")));
        break;

      case "N":
        this.setState((this.uitvoeringState = this.changeUitvoering("N")));
        break;
      case "L":
        this.setState((this.uitvoeringState = this.changeUitvoering("L")));
        break;
      case "NT":
        this.setState((this.uitvoeringState = this.changeUitvoering("NT")));
        break;

      case "GW":
        this.setState((this.uitvoeringState = this.changeUitvoering("GW")));
        break;
      case "GB":
        this.setState((this.uitvoeringState = this.changeUitvoering("GB")));
        break;

      default:
        this.setState((this.visibleModalState = this.changeModal(eventKey)));
    }
  };

  render() {
    const accessToken = sessionStorage.getItem("accessToken");
    //console.log("USR: ", accessToken)
    //this.handleOpenProj()

    if (accessToken) {
      const menuShow = (mItems: any) => {
        return mItems.map((item: any, index: any) => {
          if (item.submenu) {
            return (
              <NavDropdown
                title={item.label}
                key={index}
                className="dropdown-menu-dark"
                data-bs-theme="dark"
              >
                {menuShow(item.submenu)}
              </NavDropdown>
            );
          } else {
            if (item.location) {
              return (
                <Nav.Link
                  href={item.url}
                  key={index}
                  eventKey={item.eventKey}
                  className="ml-auto"
                  //onSelect={this.handleSelect(1)}
                >
                  {item.label}
                </Nav.Link>
              );
            } else {
              return (
                <Nav.Link
                  href={item.url}
                  key={index}
                  eventKey={item.eventKey}

                  //onSelect={this.handleSelect(1)}
                >
                  {item.label}
                </Nav.Link>
              );
            }
          }
        });
      };
      const navStyle = {
        color: "black",
        fontWeight: "bold",
      };

      return (
        <>
          <Container className="nav-container">
            <Navbar bg="dark" expand="lg" variant="dark">
              <Navbar.Brand>
                <h5
                  style={{
                    color: "White",
                  }}
                >
                  <b> Qbus Project Calculator </b>
                </h5>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav
                  onSelect={this.handleSelect}
                  className="mr-auto"
                  style={navStyle}
                >
                  {menuShow(this.navBarData)}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
            <Infobar
              onFullChanged={this.handleFullChange}
              onInDienstChanged={this.handleInDienstChanged}
            />

            <Opbouw
              tempWoning={this.woningToLoad}
              loadedWoning={this.loadedWoning}
              onDataChange={this.handleWoningChanged}
              uitvoering={this.state.uitvoering}
              onModulesUpdated={this.handleModules}
              full={this.state.full}
              paketten={this.state.paketten}
              indienst={this.state.indienst}
              rel04_08={this.state.rel04_08}
              onInDienstChanged={this.handleInDienstChanged}
            />

            <Settings
              visible={this.state.visibleModal}
              onDataChange={this.handleSelect}
              onPakettenChange={this.handlePakketChanged}
              paketten={this.state.paketten}
              rel04_08={this.state.rel04_08}
              onREL04_08Changed={this.handleREL04_08Changed}
            />

            <Options
              visible={this.state.visibleModal}
              onDataChange={this.handleSelect}
            />
            <Projects
              visible={this.state.visibleModal}
              projects={this.projects}
              onDataChange={this.handleSelect}
              onOpenProject={this.handleOpenProject}
            />
            <SaveAs
              visible={this.state.visibleModal}
              onDataChange={this.handleSelect}
              onSave={this.handleSaveProject}
            />

            <Errors
              visible={this.state.errorsVisible}
              message={this.state.errorMessage}
              onDataChange={this.handleErrorScreen}
            />
          </Container>
        </>
      );
    } else {
      {
        window.location.href = "/Login";
      }
    }
  }
}

export default NavBar;
