//import * as React from 'react';
import React, { Component, useEffect } from "react";

import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { styled, alpha } from "@mui/material/styles";
import Card from "react-bootstrap/Card";
import CardHeader from "react-bootstrap/CardHeader";
import Nav from "react-bootstrap/Nav";
import Errors from "../../Screens/error";
import * as myTreeIcons from "../../images/icons/treeicons";
import { Button, CardBody, Form } from "react-bootstrap";
import "./outputmodules.css";
import * as functions from "./moduleFunctions";
import ingangen from "../../data/ingangen.json";
import uitgangen from "../../data/uitgangen.json";
import IoName from "./ioname";
import DmxOptions from "./dmx";

//import * as itemsDbFunctions from './../../database/items'

//import { GetOutputsFromDatabase } from "./../../database/items";

import { Icon, ThemeProvider, createTheme } from "@mui/material";

import { WoningIcon } from "../../images/icons/svgIcons";

interface MyProps {
  woningSlectedId: { id: string; type: string; label: string };
  onDataChange: (t: any, parent: any) => any;
  uitvoering: string;
}

interface MyState {
  selectedView: string;
  outputs: OutputModules[];
  inputs: OutputModules[];
  errorsVisible: boolean;
  errorMessage: string;
  ruimteNaam: string;
  aantio: number;
  dmxVisible: boolean;
}

interface OutputModules {
  nodeid: string;
  label: string;
  type: string;
  parent: string;
  typeIcon: string;
  moduleType: string;
}

//interface modulesFromDatabase extends Array<moduleFromDatabase>{}

let id = 50;

class OutputModulesTree extends Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      selectedView: "uitgangen",
      outputs: [],
      inputs: [],
      errorsVisible: false,
      errorMessage: "",
      ruimteNaam: "",
      aantio: 6,
      dmxVisible: false,
    };
  }

  outputItems: OutputModules[] = JSON.parse(
    sessionStorage.getItem("modulesOutputs") || "{}"
  );
  inputItems: OutputModules[] = JSON.parse(
    sessionStorage.getItem("modulesInputs") || "{}"
  );

  itemToAddIoNaam: boolean = true;
  itemToAddRuimteNaam: boolean = false;
  itemToAddEigenNaam: boolean = false;
  itemToAddIoNaamText: string = "";
  itemToAddAantal = 1;

  treeItemsArray: string[] = []; //myTreeIcons.ioItemArray(this.selectedTree)

  myNodeId = 0;

  componentDidMount(): void {
    if (this.state.outputs.length === 0) {
      //this.getOutputs();
      this.setState({ outputs: this.outputItems });
    }
    if (this.state.inputs.length === 0) {
      //this.getOutputs();
      this.setState({ inputs: this.inputItems });
    }
  }

  increaseId() {
    let id = Number(sessionStorage.getItem("nodeId"));
    //debugger
    if (id) {
      let idN = id + 1;
      sessionStorage.setItem("nodeId", idN.toString());
    }
  }

  selectedWoningid = this.props.woningSlectedId;

  addOutputToBuildingTree() {}

  getType(arr: myTreeIcons.OutputModules[], nodeId: any) {
    let selLabel = "";
    let selMod = "";
    let selTypeIcon = "";
    let ioName = "";

    arr.map((treenode) => {
      if (treenode.nodeid === nodeId) {
        selTypeIcon = treenode.typeIcon;
        //selLabel = this.itemToAddIoNaamText //treenode.label;
        selMod = treenode.moduleType;

        if (this.itemToAddRuimteNaam === true) {
          if (this.selectedWoningid.label !== "") {
            ioName = this.selectedWoningid.label;
          }
        }

        if (this.itemToAddIoNaam === true) {
          if (treenode.label !== "") {
            if (ioName !== "") {
              ioName = ioName + " - " + treenode.label;
            } else {
              ioName = treenode.label;
            }
          }
        }
        //if (this.itemToAddEigenNaam === true) {
        if (this.itemToAddIoNaamText != "") {
          if (ioName !== "") {
            ioName = ioName + " - " + this.itemToAddIoNaamText;
          } else {
            ioName = this.itemToAddIoNaamText;
          }
        }
        //}

        selLabel = ioName;
      }
      return "";
    });

    return [selLabel, selMod, selTypeIcon];
  }

  addIoToTree(selMod: string, selTypeIcon: string, selLabel: string, outputOptions: {}) {
    if (selMod !== "category") {
      // Data to save in database
      let type = "";

      // Change i/o type if SWC selected
      if (selMod.includes("SWC") || selMod.includes("MDI")) {
        type = selMod + "/" + sessionStorage.getItem("uitvoering"); // this.props.uitvoering; => 101 / 122 / SWCN / SWCL
      } else {
        type = selMod;
      }

      if (type === "SWC01/NNB" || type === "SWC02/NNB") {
        type = "SWC0102/NNB";
      }

      if (type === "SWC01T/NNB" || type === "SWC02T/NNB") {
        type = "SWC0102T/NNB";
      }

      let aant = 1;

      // If Op/Neer (rolluik op relais -> change type to relais)
      let type2Check = type;
      if (type === "ROLO") {
        aant = 2;
        type2Check = "REL";
      }

      // Check if module exists in database
      const retMod = functions.checkMod(type2Check);

      // If mod found - continue
      // Else show error - Mod does not exists
      // Example error (no SWC04/100)
      if (retMod.found === true) {
        // ToDo - Check why we do this!
        if (this.itemToAddAantal != 1) {
          aant = this.itemToAddAantal;
        }

        // ToCheck- add node on top of tree ??
        if (retMod.parentModule) {
          const modToAdd = functions.getModById(retMod.parentModule);

          let dbItem = {
            nodeid: sessionStorage.getItem("nodeId")?.toString(),
            label: modToAdd.name,
            type: modToAdd.type,
            parent: "0",
            typeIcon: selTypeIcon,
          };
          this.props.onDataChange(dbItem, "0");
          this.increaseId();
        }

        // Finaly add our mod to Buildingtree
        if (aant != 1) {
          for (let i = 0; i < aant; i++) {
            let dbItem = {
              nodeid: sessionStorage.getItem("nodeId")?.toString(),
              label: selLabel + " " + (i+1),
              type: type,
              parent: this.selectedWoningid.id,
              typeIcon: selTypeIcon,
              outputOptions: outputOptions
            };
            this.props.onDataChange(dbItem, this.selectedWoningid.id);
            this.increaseId();
          }
        } else {
          let dbItem = {
            nodeid: sessionStorage.getItem("nodeId")?.toString(),
            label: selLabel,
            type: type,
            parent: this.selectedWoningid.id,
            typeIcon: selTypeIcon,
            outputOptions: outputOptions
          };
          if (selMod === "DMX") {
            dbItem = {
              nodeid: sessionStorage.getItem("nodeId")?.toString(),
              label: selLabel,
              type: type,
              parent: this.selectedWoningid.id,
              typeIcon: selTypeIcon,
              outputOptions: outputOptions
            };
          } 
          this.props.onDataChange(dbItem, this.selectedWoningid.id);
          this.increaseId();
        }
      } else {
        this.setState({ errorMessage: "Deze module bestaat niet." });
        this.setState({ errorsVisible: true });
      }
    }
  }

  action = (event: any, nodeId: any) => {
    // Get Type of selected buildingtree item
    const woningTreeSelectedType = this.selectedWoningid.type;

    // If type is 'woning' - continue
    // Else (output selected) - show error message
    if (woningTreeSelectedType === "woning") {
      let arr: myTreeIcons.OutputModules[] = [];

      // Check if output is of type 'input or output'
      if (this.state.selectedView === "uitgangen") {
        arr = this.state.outputs;
        this.treeItemsArray = myTreeIcons.ioItemArray(this.state.outputs);
      } else if (this.state.selectedView === "ingangen") {
        arr = this.state.inputs;
        this.treeItemsArray = myTreeIcons.ioItemArray(this.state.inputs);
      }

      // Get label, mod and type of i/o
      const [selLabel, selMod, selTypeIcon] = this.getType(arr, nodeId);
      this.myNodeId = nodeId
      //debugger

      if (selMod === "DMX") {
        this.setState({dmxVisible: true})
      } else {
        // Add i/o to BuildingTree
        this.addIoToTree(selMod, selTypeIcon, selLabel, {});
      }

      


    } else {
      this.setState({ errorMessage: "Geen ruimte gekozen." });
      this.setState({ errorsVisible: true });
    }
  };

  addRuimte = () => {
    const icon: any = (
      <Icon key="woning">
        <WoningIcon />
      </Icon>
    );

    //console.log("ID: ", this.props.getNodeId)
    //let nid = sessionStorage.getItem('nodeId');

    let dbItem = {
      nodeid: sessionStorage.getItem("nodeId")?.toString(),
      label: this.naamText,
      type: "woning",
      parent: this.selectedWoningid.id,
      typeIcon: "floor",
      moduleType: "",
    };

    this.props.onDataChange(dbItem, this.selectedWoningid.id);
    this.increaseId();

    //this.ruimteName = ""
    this.setState({
      ruimteNaam: "",
    });

    this.naamText = "";
  };

  naamText = "";
  onNaamChange = (event: any) => {
    ///console.log(event)
    this.naamText = event.target.value;
    this.setState({
      ruimteNaam: this.naamText,
    });

    if (event.keyCode == 13) {
      this.addRuimte();
    }
  };

  onNameKeyPress = (event: any) => {
    if (event.keyCode == 13) {
      this.addRuimte();
    }
  };

  renderRuimtes = () => {
    const b1 = (
      <>
        <Form.Group>
          <Form.Label>Geef een naam voor de ruimte:</Form.Label>
          <Form.Control
            id="ruimtenaam"
            name="ruimtenaam"
            aria-describedby=""
            onChange={this.onNaamChange}
            onKeyDown={this.onNameKeyPress}
            value={this.state.ruimteNaam}
          />

          <Button onClick={this.addRuimte}> Voeg ruimte toe</Button>
        </Form.Group>
      </>
    );

    return b1;
  };

  treeItemCounterl = 0;

  createIoTrees(parents: myTreeIcons.OutputModules[] = []) {
    //console.log("P: ", parents)

    const kamerArray = myTreeIcons.handleParentArray(parents);
    //console.log(kamerArray)

    const selItems = "ioNode_" + this.state.selectedView;

    if (this.state.selectedView === "uitgangen") {
      //arr = this.state.outputs
      this.treeItemsArray = myTreeIcons.ioItemArray(this.state.outputs);
    } else if (this.state.selectedView === "ingangen") {
      //arr = this.state.inputs
      this.treeItemsArray = myTreeIcons.ioItemArray(this.state.inputs);
    }

    //console.log("KA/ ", kamerArray)
    //console.log("MYARR: ", this.treeItemsArray)

    const renderTree = (nodes: myTreeIcons.kamerItem) => (
      <TreeView
        className="treeview_buildingtree"
        aria-label="customized"
        key={selItems + this.treeItemCounterl++}
        expanded={this.treeItemsArray}
        //sx={{  overflowX: "hidden", }}
        onNodeSelect={this.action}
      >
        <TreeItem
          className="treeitem_buildingtree"
          key={nodes.key}
          nodeId={nodes.id}
          label={nodes.name}
          icon={nodes.icon}
          sx={{ overflowX: "hidden", fontSize: 18 }}
        >
          {Array.isArray(nodes.children)
            ? nodes.children.map((node) => renderTree(node))
            : null}
        </TreeItem>
      </TreeView>
    );

    return renderTree(kamerArray);
  }

  // items2load komt van json (uitgangen)
  // Todo: get uitgangen from databasa
  //  let oa = null
  renderTreeItems = (itemsToLoad: OutputModules[]) => {
    const parents: OutputModules[] = [];

    itemsToLoad.map((treenode) => {
      if (!parents.includes(treenode)) {
        parents.push(treenode);
      }
      return "";
    });

    let catArra: OutputModules[] = [];
    let compArray = [];

    var sortedArray: OutputModules[] = parents.sort(
      (n1, n2) => parseInt(n1.nodeid) - parseInt(n2.nodeid)
    );
    for (var treetype of sortedArray) {
      if (treetype.moduleType === "category") {
        catArra = myTreeIcons.getItemsByCat(sortedArray, treetype.nodeid);

        compArray.push(this.createIoTrees(catArra));
        //console.log(compArray)
      }
    }

    const CustomFontTheme = createTheme({
      typography: {
        fontSize: 11,
      },
    });

    const renderTree = (nodes: any) => (
      <ThemeProvider theme={CustomFontTheme}>
        {Array.isArray(nodes) ? nodes.map((node) => node) : null}
      </ThemeProvider>
    );

    return renderTree(compArray);
  };

  handleClick = () => this.setState({ selectedView: "uitgangen" });

  handleSelect = (eventKey: any) => {
    if (`${eventKey}` === "uitgangen") {
      this.setState({ selectedView: "uitgangen" });
    } else if (`${eventKey}` === "ingangen") {
      this.setState({ selectedView: "ingangen" });
    } else if (`${eventKey}` === "ruimtes") {
      this.setState({ selectedView: "ruimtes" });
    }
  };

  handleErrorScreen = (errorsVisisble: boolean) => {
    this.setState({ errorsVisible: errorsVisisble });
  };

  handleDmxScreen = (dmxVisible: boolean, id: number) => {
    this.setState({ dmxVisible: dmxVisible });
    const woningTreeSelectedType = this.selectedWoningid.type;

    // If type is 'woning' - continue
    // Else (output selected) - show error message
    if (woningTreeSelectedType === "woning") {
      let arr: myTreeIcons.OutputModules[] = [];

      // Check if output is of type 'input or output'
      if (this.state.selectedView === "uitgangen") {
        arr = this.state.outputs;
        this.treeItemsArray = myTreeIcons.ioItemArray(this.state.outputs);
      } else if (this.state.selectedView === "ingangen") {
        arr = this.state.inputs;
        this.treeItemsArray = myTreeIcons.ioItemArray(this.state.inputs);
      }

      // Get label, mod and type of i/o
      const [selLabel, selMod, selTypeIcon] = this.getType(arr, this.myNodeId);
      //debugger

      const outputOptions = {"DmxType": id}
      this.addIoToTree(selMod, selTypeIcon, selLabel, outputOptions);


      


    } else {
      this.setState({ errorMessage: "Geen ruimte gekozen." });
      this.setState({ errorsVisible: true });
    }
  };

  handleDmxSelect = (dmxId: string) => {
    console.log(dmxId);
  };

  HanleNameChange = (naam: string) => {
    this.itemToAddIoNaamText = naam;
  };

  HanleAantalOutputsChange = (aantal: number) => {
    this.itemToAddAantal = aantal;
  };

  HanleonEigenNaamCheckChange = (check: boolean) => {
    //console.log(check)
    this.itemToAddEigenNaam = check;
  };

  HanleUitgangNaamCheckChange = (check: boolean) => {
    //console.log(check);
    this.itemToAddIoNaam = check;
  };

  HanleRuimtenNaamCheckChange = (check: boolean) => {
    //console.log(check);
    this.itemToAddRuimteNaam = check;
  };

  render() {
    //this.handleErrorScreen(true)
    return (
      <>
        <Card className="card_buildingtree" style={{ height: "95vh" }}>
          <CardHeader>
            <Nav
              variant="pills"
              defaultActiveKey="/home"
              onSelect={this.handleSelect}
            >
              <Nav.Item>
                <Nav.Link eventKey="ruimtes" className="building-nav">
                  Ruimtes
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="uitgangen" className="building-nav">
                  Uitgangen
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="ingangen" className="building-nav">
                  Ingangen
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </CardHeader>

          {this.state.selectedView != "ruimtes" ? (
            <IoName
              onNameChange={this.HanleNameChange}
              onAantalChange={this.HanleAantalOutputsChange}
              onEigenNaamCheckChange={this.HanleonEigenNaamCheckChange}
              onUitgangNaamCheckChange={this.HanleUitgangNaamCheckChange}
              onRuimtenNaamCheckChange={this.HanleRuimtenNaamCheckChange}
            />
          ) : (
            ""
          )}
          <CardBody className="card_mods_body">
            {this.state.selectedView === "ruimtes" ? this.renderRuimtes() : ""}

            {this.state.selectedView === "uitgangen"
              ? this.renderTreeItems(this.outputItems)
              : ""}
            {this.state.selectedView === "ingangen"
              ? this.renderTreeItems(this.inputItems)
              : ""}
          </CardBody>
        </Card>

        <Errors
          visible={this.state.errorsVisible}
          message={this.state.errorMessage}
          onDataChange={this.handleErrorScreen}
        />

        <DmxOptions
          visible={this.state.dmxVisible} //{this.state.dmxVisible}
          onDmxClose={this.handleDmxScreen}
          onDmxSelect={this.handleDmxSelect}
        />
      </>
    );
  }
}
export default OutputModulesTree;
